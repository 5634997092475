.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: 1px solid #EFEADD;
    background: white;
    font-weight: normal;
    color: #000000;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    border: 1px solid #000000;
    background: rgba(177, 210, 71, 0.4);
    color: #000000;
    font-size: 11px;
}


.ui-datepicker td span, .ui-datepicker td span{
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none;
    background-color: white !important;
}

.ui-widget-content a {
    color: #000000;
    display: block;
    height: 22px;
    background-color: rgba(177, 210, 71, 0.4) !important;
    font-size: 11px;
}

.ui-widget-header{
    border: 1px solid #000000;
    background: none;
    color: #000000;
    font-weight: bold;
}

.ui-widget-content {
    border: 1px solid #000000;
    background: #ffffff;
    color: #000000;
    z-index: 9999 !important;
}

.ui-datepicker-unselectable.unselectable_red span.ui-state-default {
    background:#FFC0c0 !important;
    text-decoration: none;
}